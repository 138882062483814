<template>
  <div class="subitemV4" @click.stop="clickItem">
    <div class="header">
      <div class="type" v-html="formatType(dataInfo.severity)"></div>
      <div class="orderId textEllipsis">
        工单编号：{{ dataInfo.serialNumber }}
      </div>
      <div class="status" v-html="formatStatus(dataInfo.status)"></div>
    </div>
    <div class="content">
      <SubItem
          @clickAction="clickItem"
          v-for="item in rowKeyList"
          :key="item.key"
          :bracket="false"
          :spacing="item.space"
          :item-info="getKeyValue(item)"
          :width="'100%'"
      ></SubItem>
    </div>
    <div class="footder">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import SubItem from '@/views/shareAndDispatch/device/deviceList/subItem'
import shareDeviceOrderStatus from '@components/shareDeviceOrderStatus/shareDeviceOrderStatus'

export default {
  name: 'subitemV4',
  components: { SubItem },
  mixins: [shareDeviceOrderStatus],
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rowKeyList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  created() {
  },
  methods: {
    clickItem: function() {
      this.$emit('clickItem', this.dataInfo)
    },
    getKeyValue: function(item) {
      let data = {
        title: item.name,
        value: '',
        showDetail: false,
        detailValue: '',
        float: 'left'
      }

      if (this.$valueIsExist(item, 'float')) {
        data['float'] = item['float']
      }
      if (this.$valueIsExist(item, 'float')) {
        data['float'] = item['float']
      }

      if (this.$valueIsExist(this.dataInfo, item.key)) {
        let _value = this.dataInfo[item.key]
        if (_value != '' || _value != undefined || _value != null) {
          data.value = _value
        } else {
          data.value = ''
        }
        if (item.key == 'reportTime') {
          data.value = this.formatTimeDurationToSecond(data.value)
          data.showDetail = true
          data.detailValue = this.formatDate(_value)
        }
      }
      return data
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.subitemV4 {
  width: 100%;
  padding: 8px 10px;
  background: linear-gradient(180deg, #d1deff 0%, #ffffff 100%);
  border-radius: 8px;
  margin-bottom: 10px;

  .header {
    font-size: 16px;
    font-weight: 700;
    padding: 0 6px 0 0;
    margin-bottom: 4px;

    .type {
      display: inline-block;
      color: #ffffff;
      width: 50px;
      line-height: 24px;
      text-align: center;
      margin-right: 8px;
      font-size: 14px;
    }

    .orderId {
      width: calc(100% - 130px);
      color: $color1;
      display: inline-block;
      vertical-align: bottom;
    }

    .status {
      width: 72px;
      display: inline-block;
      text-align: right;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 4px;
  }

  .footder {
    text-align: right;
    padding: 0 6px;

    .headleBtn {
      display: inline-block;
      width: 102px;
      height: 32px;
      border-radius: 16px;
      line-height: 32px;
      text-align: center;
      color: #3e73fb;
      border: 1px solid #3e73fb;
      margin-left: 8px;
      font-size: 14px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>