<template>
  <div class="subItem" :style="{ width: width }" @click.stop="clickAction">
    <div
      v-if="itemInfo.title != ''"
      class="title"
      :style="{ 'word-spacing': $changePxToRem(spacing) }"
    >
      {{ itemInfo.title }}
    </div>


    <el-tooltip :hide-after="3000"  :disabled="tooltipDisabled" class="item" effect="dark" :content="tooltipContent" placement="top-start">
      <div :style="{'--float':float,color:color,'text-align':align}" v-if="itemInfo.value != ''" class="value">
        {{ bracket ? "(" : "" }}
        <span v-html="itemInfo.value"></span>
        {{ bracket ? ")" : "" }}
      </div>
    </el-tooltip>


  </div>
</template>

<script>
export default {
  name: "subItem",
  computed:{
    float() {
      if (this.$valueIsExist(this.itemInfo, 'float') == false) {
        return 'left';
      }
      return this.itemInfo['float']
    },

    tooltipContent() {
      if (this.$valueIsExist(this.itemInfo, 'detailValue') == false) {
        return '';
      }
      return this.itemInfo['detailValue']
    },
    tooltipDisabled() {
      if (this.$valueIsExist(this.itemInfo, 'showDetail') == false) {
        return true;
      }
      return !this.itemInfo['showDetail']
    },
  },
  props: {
    bracket: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: "right",
    },
    spacing: {
      type: String,
      default: "9px",
    },
    color: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    itemInfo: {
      type: Object,
      default: () => {
        return {
          title: "",
          value: "",
        };
      },
    },
  },
  methods:{
    clickAction:function(res) {
      if (this.tooltipDisabled == false) {
        return;
      }
      this.$emit('clickAction', {})
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";


.subItem {
  line-height: 24px;
  display: flex;
  .title {
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
    max-width: 80px;
    float: left;
    text-align: justify;
    text-align-last: justify;
    padding-left: 8px;
    word-break: break-all;
    font-size: 12px;
    color: $color2;
  }
  .value {
    position: relative;
    top: 50%;
    padding-left: 5px;
    transform: translate(0px, -50%);
    flex: 1;
    float: var(--float);
    text-align: var(--float);
    word-break: break-all;
    font-size: 12px;
    //font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: black;
    padding-right: 8px;
  }
}
</style>
